.main-footer {
    align-items: center;
    background: #54585A;
    font-family: 'Montserrat', sans-serif;
    padding: 22px 0;
    user-select: none;

    &__item {
        color: rgba(255,255,255,0.7);
        cursor: pointer;
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 1.2px;
        margin-right: 35.5px;

        &:hover, &.selected-tab {
            color: #FFBD0C;
        }
    }
    .copyright {
        color: rgba(255,255,255,0.7);
        font-size: 10px;
        letter-spacing: 1px;
        margin-top: 30px;
    }
    @media screen and (max-device-width: 1200px) {
        &__item {
            font-size: 8px;
            margin-right: 10px;
            letter-spacing: normal;
        }
    }
    .social {
        margin-bottom: 20px;
        .main-footer__item {
            margin-right: 15px;
        }
        a {    
            &:hover {
                color: #FFBD0C;
            }
            .fab {
                font-size: 2.5em;
            }
        }
        p {
            &:hover {
                cursor: auto;
                color: rgba(255, 255, 255, 0.7);
            }
        }
    }
}
@import '../../../../src/scss/utilities';
@import '../../../../src/scss/variables';

.met-with-wrapper {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 1rem !important;

  input {
    max-width: 250px !important;
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  position: relative;

  @include for-tablet-down {
    &.special-length {
      // 20px: rodio/checkbox width
      // 1rem: gap
      // Fixme
      width: calc(100% - 20px - 1rem) !important;
    }
  }

  &.special-length {
    width: 290px;
  }

  &.extra-length {
    width: 400px;
  }

  .input-labels-container {
    display: flex;
    flex-direction: column;
  }

  .suspicion-label {
    color: $teal !important;
    font-size: 16px !important;
    font-weight: normal !important;
    white-space: nowrap;
  }

  .input-name-label {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-align: left;

    &.show-label {
      transition: all 0.5s ease;
      color: rgba(84, 88, 90, 0.5);
      font-family: Montserrat, sans-serif;

      &.greenish {
        color: $teal;
      }

      &.redish {
        color: #FF6666;
      }
    }
  }

  .input-text {
    background-color: #fff;
    border: 1px solid rgba(84, 88, 90, 0.2);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    height: 38px;
    padding: 0 12px;
    outline: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: rgba(84, 88, 90, 0.5);
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-weight: 600;
    }

    &--has-value {
      color: #54585A;
      font-size: 16px;
    }

    &.has-error {
      border: 1px solid #FF6666;
    }

    &.is-valid {
      border: 1px solid #7DC0BD;
    }

    &:focus, &:active {
      border: 1px solid #54585A;

    }

    &:disabled {
      opacity: 0.5;
    }

    &::placeholder {
      font-size: 1em;
    }
  }

  .error-label {
    top: 38px;
    color: #FF596F;
    font-size: 12px;
    font-weight: 400;
    min-width: 15px;
    position: absolute;
    right: 10px;
    text-align: right;
    user-select: none;
  }

  &.no-borders {
    .input-text {
      border: none;
    }

    .has-error-label {
      position: absolute;
      bottom: -25px;
      width: 200px;
    }
  }

  &.margin-right {
    margin-right: 30px;
  }

  &.margin-bottom {
    margin-bottom: 30px;
  }

  &.margin-20 {
    margin-right: 20px;
  }

  &.kids-age {
    width: 50px;
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
  }
}

.input-wrapper.input-price {
  &:before {
    color: #54585A;
    content: '$';
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    left: 8px;
    line-height: 40px;
  }

  input {
    padding-left: 20px;
  }
}



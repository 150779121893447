@import "../../../src/scss/variables";

.ds-loader,
.ds-loader:before,
.ds-loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    margin-bottom: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}

.ds-loader {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    margin: 100px auto 0;
    color: $teal;

    &--white {
        color: #ffffff;
    }
}

.ds-loader--small,
.ds-loader--small:before,
.ds-loader--small:after {
    width: 20px;
    height: 20px;
}

.ds-loader:before,
.ds-loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.ds-loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.ds-loader:after {
    left: 3.5em;
}

@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

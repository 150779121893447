@import "../../../src/scss/variables";

.login-container, .matchmaker-sign-up-container, .contact-page-container {
    height: 100vh;
    .right-section {
        // width: calc(100% - 490px);
        @media(min-width: 992px) {
            width: 50%;
            height: 100vh;
        }
        .couple-info {
            margin: auto 0 20px auto;
        }
    }
    .left-section {
        background: #fff;
        position: relative;
        overflow: scroll;
        // min-width: 490px;
        // max-width: 490px;

        @media(min-width: 992px) {
            height: 100vh;
        }

        user-select: none;
        .welcome-to-the-team {
            padding: 0 60px 0;
            .title {
                color: $teal;
                font-family: Montserrat, sans-serif;
                font-size: 32px;
                font-weight: 500;
                text-align: center;
                white-space: pre-wrap;
            }
            .text {
                color: #54585A;
                font-family: 'Montserrat', sans-serif;
                font-size: 20px;
                line-height: 32px;
                padding: 50px 0;
            }
            .button {
                margin: 0 auto;
            }
        }
        .agree-terms {
            font-size: 13px;
            line-height: 12px;
            .input-checkbox .checkbox__label {
                padding-left: 12px;
                margin-top: -3px;

            }
        }
        .sign-up-with-fb {
            background-color: #4267B2;
            cursor: pointer;
            min-height: 46px;
            margin: 20px 60px 0;
            &.short {
                margin: 20px 32px 0;
            }
            .icon-fb {
                font-size: 46px;
            }
            &__text {
                color: #fff;
                font-family: Montserrat, sans-serif;
                font-size: 14px;
                font-weight: 900;
                letter-spacing: 0.93px;
                text-transform: uppercase;
                text-align: center;
                width: 100%;
            }
        }
        .separator {
            color: rgba(84,88,90,0.5);
            font-size: 16px;
            font-family: Montserrat, sans-serif;
            padding: 9px 0 10px 0;
            text-align: center;
            width: 100%;
        }
        .find-your-partner {
            color: $teal;
            font-size: 28px;
            font-weight: bold;
            font-family: Montserrat, sans-serif;
            margin: 0 0 20px 0;
            text-align: center;
        }
        .back-to-datespot {
            padding: 0 12px;
        }
    }
    .logo-and-arrow {
        min-height: 60px;
        margin: 20px;
        .icon-back-button {
            align-self: flex-start;
            cursor: pointer;
        }
        .date-spot-logo {
            width: calc(100% - 30px);
            img { width: 106px; }
            &.large {
                img {
                    margin: 73px 0 60px 0;
                    width: 187px;
                }
            }
        }
    }
    .login-background {
        height: 100%;
        background: url("home-pages/assets/signup-login-pages/k.jpg");
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;

    }
    .sign-up-background {
        height: 100%;
        background: url("/images/Jacky&Tim.jpg") no-repeat;
        background-size: cover;
        @media screen and (max-width: 1200px) {
            background: url("/images/Jacky&Tim.jpg") no-repeat -240px 0;
        }
    }
    .contact-background {
        height: 100%;
        background: url("home-pages/assets/contact-page/contact1.jpg") no-repeat;
        background-size: cover;
        background-position: top center;
        @media(min-width: 1600px) {
            // background-size: contain;
            flex-shrink: 2;
            background-position: top center;
        }
        &.new-member-background {
            // background: url("home-pages/assets/homepage/singles-page-1.jpg") no-repeat -305px 0;
            background: url("home-pages/assets/homepage/hands.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            @media(max-width: 1100px) {
                // background: url("home-pages/assets/homepage/hands.jpg");
                background-size: cover;
                // background-repeat: no-repeat;
                // background-position: center;
            }
        }
    }
    .talk-to-a-matchmaker-background {
        height: 100%;
        background: url("home-pages/assets/talk-to-a-mm-page/meaghandandrew.png") no-repeat -230px 19px;
        background-size: cover;
        @media screen and (max-width: 1200px) {
            background: url("home-pages/assets/talk-to-a-mm-page/meaghandandrew.png") no-repeat -430px 40px;
        }
    }
    .sign-up-container__form, .login-container__form {
        padding: 0 60px 30px 60px;
        .error-label {
            font-family: 'Montserrat', sans-serif;
            top: -18px;
            font-size: 14px;
            min-width: 2px;
        }
        .error-custom {
            font-family: 'Montserrat', sans-serif;
            color: #FF596F;
            font-size: 12px;
            font-weight: 400;
            padding-left: 1em;
            &.agree-terms {
                margin: -15px 0 10px 32px;
            }
        }
    }
    .sign-up-container__form {
        padding: 0 40px 30px 40px;
    }
    &__form {
        .input-wrapper {
            width: 100%;
            margin-bottom: 21px;
        }

        .want-to-be-matchmaker {
            color: #54585A;
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 20px 0;
            text-align: center;

        }

        .forgot-password {
            color: #7DC0BD;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
            margin: 20px 0 40px 0;
            text-decoration: underline;
        }

        .or {
            margin: 20px auto;
            max-width: 200px;
            .text {
                color: rgba(84,88,90,0.5);
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                padding: 0 20px;
            }

            .line {
                background-color: rgba(84,88,90,0.2);
                height: 1px;
                width: 90px;
            }
        }

        .general-error-message {
            color: #FF596F;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            margin-top: -14px;
            padding: 10px 0 20px 0;
            text-align: right;
        }
    }
}

.login-container {
    .left-section {
        width: 50%;
        .separator {
            padding: 20px 0;
        }
    }
    &__form {
        padding: 0 60px 30px 60px;
        .sign-up-free-button {
            margin: 0 auto;
        }
    }

}
.contact-page-container {
    height: 100%;
    .left-section {
        @media(min-width: 992px) {
            width: 50%;
            height: calc(100vh - 81px);
        }
    }
    .right-section {
        position: relative;
        @media(min-width: 992px) {
            width: 50%;
            height: calc(100vh - 81px);
        }
        .button.map-button {
            bottom: 88px;
            left: 40px;
            padding: 0;
            position: absolute;
            width: 120px;
        }
        .couple-info {
            margin: auto 0 60px auto;
        }
        .map-container {
            height: 100vh;
            width: 100%;
        }
    }
    .contact-form {
        padding: 20px 40px 30px;
        &__title {
            color: $teal;
            font-family: Montserrat, sans-serif;
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 21px;
            text-align: center;
        }
        .input-wrapper {
            width: 100%;
            &.password {
                margin-bottom: 10px;
            }
            .error-label {
                top: 12px;
            }
        }
        .custom-error-label {
            color: #FF596F;
            font-size: 12px;
            font-weight: 400;
            position: absolute;
            right: 10px;
            text-align: right;
            &.normal {
                position: static;
                margin: 0 auto 20px;
                text-align: center;
            }
        }
        .names {
            margin-bottom: 10px;
            .input-wrapper {
                width: 49%;
            }
        }
        .contact-phone {
            margin: 10px 0 20px 0;
        }
        .cell-title {
            color: rgba(84,88,90,0.5);
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            margin: 0 0 3px 0;
            letter-spacing: -0.2px;
        }
        .contact-textarea {
            min-height: 135px;
            margin: 22px 0 20px
        }
        .send-message {
            margin: 0 auto;
        }
        .tool-tip-component {
            position: fixed;
            bottom: 90px;
            max-width: 150px;
            left: 270px;
            top: auto;
        }
        .other-option {
            font-size: 17px;
        }
        .talk-to-a-matchmaker-checkbox {
            .input-checkbox {
                min-height: 55px;
                .checkbox__label {
                    margin-top: -16px;
                }
            }
        }
        .agree-terms {
            color: #53585a;
            font-size: 16px;
            margin: 0 0 10px 0;
            .underlined {
                color: #7DC0BD;
                cursor: pointer;
                padding: 0 5px;
                text-decoration: underline;
            }
        }
        .companies .input-checkbox {
            min-height: 32px;
        }

        .input-text-area-wrapper .input-text-area {
            min-height: 63px;
            max-height: 200px;
        }
        .welcome-new-user-button {
            height: 70px;
            .value {
                white-space: pre-wrap;
            }
        }

        @media screen and (max-device-width: 1024px) {
            padding: 30px;
            .input-checkbox {
                margin-bottom: 10px;
            }
        }
    }
    &.welcome-new-user-container .contact-form {
        padding: 30px 60px 30px 60px;
    }
}
.contact-page-container.talk-to-a-matchmaker {
    height: 100vh;
    .budget-options {
        min-height: 80px;
    }
    .anything-else {
        min-height: 120px;
    }
    .companies {
        min-height: 170px;
    }
    .passwords {
        min-height: 110px;
    }
    .talk-button {
        min-height: 50px;
    }
    .interested-in {
        min-height: 65px;
    }
    .agree-terms {
        min-height: 40px;
    }
}
.matchmaker-sign-up-container {
    .left-section {
        @media screen and (min-device-width: 1200px) {
            min-width: 600px;
        }
        // max-width: 590px;
        width: 50%;
        .sign-up-container__form {
            .names-first {
                margin-right: 10px;
            }
        }

        .button {
            width: calc(100% - 64px);
        }
    }
    .right-section {
        // width: calc(100% - 590px);
        width: 50%;
    }
}

@media screen and (max-device-width: 1200px) {
    .contact-page-container.flex-row, .login-container.flex-row, .matchmaker-sign-up-container.flex-row {
        flex-direction: column;
        height: 100%;
        overflow: auto;
        .right-section, .left-section {
            // height: 100%;
            width: 100%;
            max-width: 100%;
        }
        .left-section {
            padding: 100px 0;
            .contact-form {
                &__title {
                    font-size: 40px;
                }
                .interested-in {
                    .cell-title {
                        font-size: 35px;
                    }
                    .input-checkbox.sign-up {
                        min-height: 140px;
                        .icon-check-empty, .icon-check-full {
                            font-size: 40px;
                        }
                        .checkbox__label .special-label span:first-child, .checkbox__label .special-label span:nth-child(2) {
                            font-size: 40px;
                            line-height: 45px;
                        }
                    }
                    .other-option {
                        font-size: 40px;
                        line-height: 43px;
                    }
                }
            }
            .find-your-partner, .sign-up-container__new-title {
                font-size: 40px;
            }
            .logo-and-arrow .date-spot-logo img {
                width: 300px;
            }
            .icon-back-button {
                font-size: 44px;
            }
            .sign-up-with-fb {
                min-height: 90px;
                &__text {
                    font-size: 35px;
                }
            }
            .separator {
                font-size: 35px;
            }
            .input-text, .input-text-area {
                min-height: 90px;
                font-size: 35px;
                &::placeholder {
                    font-size: 35px;
                }
            }
            .forgot-password, .want-to-be-matchmaker {
                font-size: 35px;
            }
            .forgot-password {
                margin: 40px 0 40px 0;
            }
            .button {
                height: 90px;
                width: calc(100% - 64px);
                .value {
                    font-size: 1.5em;
                }
            }
            .agree-terms {
                margin: 40px 0;
                font-size: 40px;
                padding-left: 60px;
                line-height: 45px;
                align-items: flex-end;
                .input-checkbox {
                    min-height: 80px;
                    margin-left: -60px;
                    .icon-check-empty, .icon-check-full {
                        font-size: 60px;
                    }
                }
                span {
                    margin-bottom: 5px;
                }
            }
            .sign-up-container {
                .cell-title {
                    font-size: 40px;
                }
                .agree-terms .underlined {
                    padding: 0 10px;
                }
                .input-checkbox {
                    min-height: 120px;
                    .icon-check-empty, .icon-check-full {
                        font-size: 60px;
                    }
                    .checkbox__label .special-label {
                        span:first-child, .checkbox__label .special-label {
                            // font-size: 40px;
                            font-size: 2em;
                            line-height: 45px;
                        }
                        span:nth-child(2) {
                            font-size: 1.5em;
                        }
                    }
                    .special-label {
                        margin-top: -55px;
                    }
                }
                .referral-sources {
                    .input-checkbox {
                        min-height: 120px;
                        width: 500px;
                        margin: 0;
                        flex: 0 0 100%;
                        .checkbox__label {
                            font-size: 40px;
                            line-height: 60px;
                        }
                    }
                    .input-wrapper {
                        // max-width: 380px;
                        max-width: none;
                        flex: 0 0 100%;
                    }
                }
            }
            .talk-to-a-mm-form {
                .drop-down-wrapper {
                    .input-text .icon-arrow {
                        font-size: 50px;
                        top: 22px;
                    }
                    .drop-down-wrapper__content {
                        top: 100px;
                    }
                    .drop-down-wrapper__list {
                        max-height: 450px;
                        &__item {
                            font-size: 30px;
                        }
                    }
                }
                .matchmaker-options__title {
                    font-size: 35px;
                    margin: 20px 0;
                }
                .talk-to-a-matchmaker-checkbox {
                    margin-bottom: 50px;
                    .input-checkbox {
                        min-height: 100px;
                        width: 100%;
                        margin: 0;
                        .icon-check-empty, .icon-check-full {
                            font-size: 60px;
                        }
                        .checkbox-icon {
                            margin-top: -10px;
                        }
                        .checkbox__label {
                            font-size: 40px;
                            line-height: 40px;
                        }
                    }
                }
                .agree-terms {
                    span {
                        margin-top: 25px;
                        font-size: 35px;
                    }
                }
            }
        }
        .right-section {
            height: 700px;
            .contact-background, .talk-to-a-matchmaker-background {
                height: 700px;
            }
            .sign-up-background {
                background: url("/images/Jacky&Tim.jpg") center 30% no-repeat;
                background-size: cover;
            }
            .map-container {
                height: 700px;
                width: 100%;
            }
        }
    }
    .contact-page-container.talk-to-a-matchmaker {
        height: 100%;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;

        .budget-options {
            min-height: 240px;
        }
        .anything-else {
            min-height: 240px;
        }
        .companies {
            min-height: 520px;
            .input-checkbox {
                min-height: 100px;
                width: 800px;
                margin: 0;
                 .icon-check-empty, .icon-check-full {
                     font-size: 60px;
                 }
                .checkbox__label {
                    font-size: 40px;
                    line-height: 57px;
                }
             }
        }
        .passwords {
            min-height: 180px;
        }
        .interested-in {
            min-height: 200px;
        }
        .talk-button {
             min-height: 150px;
        }
        .agree-terms {
            min-height: 140px;
        }
        .right-section {
            display: none;
        }
        .left-section {
            min-height: 100%;
            height: 100%;
        }
    }
}

@import "../../../../src/scss/variables";

.sign-up-modal .modal {
    max-width: 490px;
    padding: 40px 60px;
}

.sign-up-container {
    font-family: 'Montserrat', sans-serif;
    &__new-title {
        color: $teal;
        font-family: Montserrat, sans-serif;
        font-size: 32px;
        font-weight: 900;
        letter-spacing: -0.4px;
        line-height: 39px;
        padding: 0 60px 20px 60px;
        text-align: center;
    }
    &__form {
        .input-wrapper {
            width: 100%;
            margin-bottom: 21px;
            &.half-size {
                width: 48.5%;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }

        .premium-modal {
            margin: 5% 10% 5% 10%;
            h1 {
                color: $teal;
                text-align: center;
            }
            .icon-close-button {
                position: absolute;
                right: 2%;
                top: 2%;
            }
            .premium-modal-terms {
                padding-top: 0.5em;
            }
        }
        // .flex-row {
        //     flex-wrap: wrap;
        // }

        .email-input,
        .contact-phone {
            &.half-size {
                flex: 0 0 98%;

                @media (min-width: 1200px) {
                    flex: 0 1 48.5%;
                }
            }

            .error-label {
                top: -20px;
                // right: 0;
            }
        }
    }

    .birthday {
        .month-drop-down, .day-drop-down, .year-drop-down {
            margin-right: 20px;
            .drop-down-wrapper__list {
                max-height: 150px;
            }
            .input-text {
                height: 40px;
                .icon-arrow {
                    top: 11px;
                }
            }
        }

        .month-drop-down {
            width: 140px;
        }

        .day-drop-down {
            width: 90px;
        }

        .year-drop-down {
            width: 100px;
            margin-right: 0;
        }
    }

    .interested-in {
        // margin: 2em 0 1em 0;
        letter-spacing: -0.2px;

        @media (min-device-width: 771px) {
            margin: 0.15em 0;
        }
        .icon-question-mark {
            // font-size: .75em;
            padding-left: 0.5em;
            cursor: pointer;
        }

        .input-checkbox {
            margin-bottom: -0.5em;

            @media (min-device-width: 771px) {
                margin-bottom: -0.5em;
            }

            .icon-square-empty:before, .icon-square-full .path1:before {
                color: #7DC0BD;
            }
            .checkbox__label {
                span:first-of-type {
                    line-height: 1em !important;

                    @media (max-device-width: 1200px) {
                        line-height: 45px !important;
                    }
                }
            }
        }
        .checkbox-icon {
            align-self: flex-start;
            margin-top: 4px;
        }
        .cell-title {
            margin-bottom: 0.5em;
        }
    }

    .cell-title {
        // color: rgba(84,88,90,0.5);
        color: $teal;
        font-family: Montserrat, sans-serif;
        font-size: 1em;
        margin: 1px 0 2px 0;
    }

    .little-notation {
        color: rgba(84,88,90,0.5);
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        letter-spacing: 0.11px;
        margin: 0;
    }

    .custom-radio-button {
        height: 40px;
    }

    .referral-sources {
        .special-label {
            color: $teal;
            font-size: 1em;
            letter-spacing: -0.5px;
            margin: 2px -15px 2px 10px;

            @media(max-device-width: 1200px) {
                font-size: 2em;
            }
        }
    }

    .agree-terms {
        font-size: 14px;
        margin: 1em 0 2em !important;
        letter-spacing: -0.1px;

        @media (min-device-width: 771px) {
            margin: 1em 0 0.5em !important;
        }
        .accept-terms-button {
            padding: 0;
            width: 25% !important;
            margin: auto;
        }
        .underlined {
            color: #7DC0BD;
            cursor: pointer;
            padding: 0 3px;
            text-decoration: underline;
        }
        span {
            // margin-top: -18px;
            // font-size: 12px;
            display: inline-flex;
        }
    }
    &.matchmaker-sign-up {
        .agree-terms span {  margin-top: 0; }
    }
}

.terms-page-content {
    color: #54585A;
    max-width: 1000px;
    margin: 81px auto 0;
    padding: 0 50px;

    &__subtitle {
        color: #FFC134;
        font-weight: 900;
        font-size: 26px;
        margin: 30px 0 20px 0;
    }
    .text {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 20px;
        white-space: pre-wrap;
        &.small {
            font-size: 16px;
            line-height: 10px;
            margin-bottom: 10px;
        }
    }
    &__section {
        margin-bottom: 25px;
        .list {
            margin-left: 50px;
        }
    }
    .terms-and-privacy {
        color: #7DC0BD;
        cursor: pointer;
        font-size: 20px;
        padding: 0 5px;
        text-decoration: underline;
    }
    .icon-back-button {
        border-radius: 9px;
        cursor: pointer;
        position: fixed;
        top: 120px;
        left: 30px;
        height: 40px;
        width: 40px;
        background: #FFD758;
    }
}
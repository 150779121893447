.matchmaker-agreement {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    .icon-back-button {
        position: absolute;
        left: -100px;
        top: 30px;
    }
    .section-two__content {
        max-width: 1000px;
        padding: 100px 0 80px 0;
    }
    &__section {
        margin-bottom: 30px;
        &__title {
            color: #FFC134;
            font-weight: 900;
            font-size: 20px;
            font-family: 'Montserrat', sans-serif;
            margin-bottom: 20px;
        }
        &__text {
            color: #54585A;
            font-size: 20px;
            line-height: 32px;
            &.small {
                font-size: 16px;
                line-height: 10px;
                margin-bottom: 10px;
            }
        }
        &__list {
            margin-top: 10px;
            color: #54585A;
            font-size: 20px;
            line-height: 26px;
        }
    }
    &__first-text {
        color: #54585A;
        font-size: 20px;
        line-height: 32px;
        white-space: pre-wrap;
    }
    .terms-and-privacy {
        color: #7DC0BD;
        cursor: pointer;
        font-size: 20px;
        padding: 0 5px;
        text-decoration: underline;
    }
}
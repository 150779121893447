@import "../../../../../../src/scss/variables";

.referral-sources {
    padding-bottom: 20px;
    &__title {
        color: #f66;
        font-size: 18px;
        font-family: Montserrat, sans-serif;
        padding: 0 0 4px 0;
        text-align: left;
        &.green {
            color: $teal;
        }
        @media screen and (max-device-width: 1100px) {
            font-size: xx-large;
            margin-bottom: -25px;
            margin-top: 20px;
        }

    }
    .icon-arrow-down {
        cursor: pointer;
        font-size: .75em;
        @media screen and (max-device-width: 1200px) {
            font-size: 1.75em;
        }
        margin: 0px 0 0 20px;
        transition: all 0.5s ease;
        &.reverted {
            transform: rotate(180deg);
            transition: all 0.5s ease;
        }
    }
    .input-checkbox {
        min-height: 34px;
        margin: 0 20px 2px 0;
        // width: 200px;
        .checkbox__label {
            padding-left: 10px;
        }
    }
    .input-wrapper {
        margin-bottom: 0;
        max-width: 180px;
        .input-text {
            height: 30px;
        }
    }

    .special-ckb .input-checkbox .checkbox__label {
        // margin-top: -14px;
    }

    .two-pixel-margin {
        margin-bottom: 2px;
    }
}

.ds-details {
    padding: 55px 0;

    &__header {
        position: relative;
        color: #53585a;
        height: 26px;
        font-weight: bold;
        font-size: 20px;
    }

    .icon-back-button {
        cursor: pointer;
        position: absolute;
        left: 60px;
        top: 0;
        z-index: 2;
    }

    &__header-title {
        width: 100%;
        text-align: center;
    }

    hr {
        width: 50%;
        margin-bottom: 40px;
    }

    &__form {
        font-size: 20px;
        width: 50%;
        margin: 0 auto;

        > .flex-column {
            padding-bottom: 40px;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
        }

        .right-block-title {
            margin-bottom: 0;
            flex-grow: 1;
            font-size: 20px;
        }

        .input-wrapper {
            width: 310px;

            .error-label {
                left: 0;
            }

            input {
                width: 100%;
                box-sizing: border-box;
                font-size: 18px;
            }
        }

        .input-text-area-wrapper {
            width: 310px;
        }

        .ds-loader {
            margin-top: -45px;
            margin-left: 60px;
        }
    }

    .input-checkbox, .drop-down-wrapper {
        margin-left: auto;
        width: 310px;
    }

    &__text {
        font-size: 18px;
    }
}

@import "../../../../src/scss/variables";

.team-page-content {
    color: #54585A;
    margin: 0px 9.8% 0;
    .section-two__content {
        padding-bottom: 80px;
    }

    &__section {
        margin: 0 auto 100px;

        .image-panel {
            align-self: flex-start;
            background: #fff;
            border-radius: 50%;
            border: 4px solid yellow;
            // height: 100%;
            min-height: 400px;
            min-width: 400px;
            margin-top: 44px;
            //width: 100%;
            &.left {
                border: 6px solid #FFDA65;
                margin-right: 70px;
            }
            &.right {
                border: 6px solid $teal;
                margin-left: 70px;
            }
            &.extra-margin {
                margin-top: 99px;
            }
        }

        .info-panel {
            font-family: Montserrat, sans-serif;
            &__name, &__job {
                color: #FFC134;
                font-size: 32px;
                font-weight: 900;
                letter-spacing: 0.2px;
            }
            &__job {
                margin: 12px 0 14px 0;
                font-size: 20px;
                letter-spacing: -0.5px;
            }
            &__current-role {
                color: $teal;
                font-size: 20px;
                font-weight: 900;
                margin: 20px 0 10px 0;
            }
            &__text {
                color: #54585A;
                font-family: 'Montserrat', sans-serif;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: -0.1px;
                word-spacing: 3.6px;
                &.spacing {
                    margin-top: 27px;
                }
            }
            .contact-carla {
                margin-top: 20px;
            }
            .button {
                margin-top: 20px;
                height: 44px;
                width: 200px;
                padding: 0;
            }
        }
    }

    @media screen and (max-device-width: 770px) {
        margin: 81px 20px 0 30px;
        &__section {
            .image-panel.left {
                margin-right: 30px;

            }
            .image-panel.right {
                margin-left: 30px;
            }
            .info-panel {

            }

        }
    }
}

.faq-page-content {
    color: #54585A;
    max-width: 800px;
    margin: 0px auto 0;

    &__section {
        margin-bottom: 60px;
        .text {
            font-size: 20px;
            line-height: 32px;
            white-space: pre-wrap;
        }
        .answer-two {
            margin-top: 18px;
        }
    }

    &__questions {
        margin-bottom: 18px;
        .text {
            color: #FFC134;
            font-family: Montserrat, sans-serif;
            font-size: 20px;
            font-weight: 700;
        }
    }

}

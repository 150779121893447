@import './icomoon';
@import './loader.scss';
@import './details-layout.scss';
@import '../../../src/scss/variables';

@font-face {
    font-style: normal;
    font-weight: 400;
    font-family: 'Nunito Sans BAK';
    src: local('Montserrat'), local('NunitoSans'),
    url('./fonts/Nunito_Sans/Regular.ttf') format('truetype')
}

@font-face {
    font-style: normal;
    font-weight: 400;
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat'),
    url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype')
}

/* Styles */

body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    // ! Issue present on Iphones (safary browser) mainly.
    // * Using 100% instead of 100vh to prevent issue in which the mobile browser address...
    // * ...bar would get over the page as 100vh is the height of the viewport (entire screen) and...
    // * ...100% is the height of the parent element (which excludes the address bar and navigation buttons).
    height: 100%;
    position: fixed;

    .gap-20 {
        gap: 20px;
    }

    &.no-scroll {
        overflow: hidden;
    }

    &.long-scroll {
        &::-webkit-scrollbar-track {
            margin: 160px 0 80px 0;
        }
    }

    &.small-scroll {
        &::-webkit-scrollbar-track {
            margin: 160px 0 80px 0;
        }
    }

    .subtitle {
        margin: .25rem 0 0 0;
        font-size: .75rem;
        color: $greyLight;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.wrap {
            flex-wrap: wrap;
        }

        &.gap {
            gap: 1rem;
        }
    }

    .flex-column {
        display: flex;
        flex-direction: column;

        &.wrap {
            flex-wrap: wrap;
        }
    }

    .full-width {
        width: 100% !important;
    }

    .full-height {
        height: 100% !important;
    }

    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .flex-vertical-center {
        display: flex;
        align-items: center;
    }

    .flex-horizontal-center {
        display: flex;
        justify-content: center;
    }

    .float-right {
        margin-left: auto;
    }

    .float-left {
        margin-right: auto;
    }

    .float-up {
        margin-bottom: auto;
    }

    .float-down {
        margin-top: auto;
    }

    .couple-info {
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 500;
        height: 38px;
        margin: 200px 0 0 auto;
        padding: 20px 40px;
        text-align: center;
        max-width: 180px;
        z-index: 4;

        span:first-child {
            font-weight: 900;
        }
    }

    .request-info-email {
        &__button {
            background: $teal;
            border: 2px solid $teal;
            color: #fff !important;
            cursor: pointer;
            font-size: 14px;
            font-weight: 800;
            outline: none;
            padding: 12px 40px;
            text-transform: uppercase;
            text-decoration: none;
            transition: all 0.5s;
            margin-right: 20px
        }

        &__button:hover {
            background-color: #56A3A0;
            border: 2px solid #56A3A0;
        }
    }


}



@import '../../../src/scss/variables';

@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?o522a7');
    src:  url('fonts/icomoon.eot?o522a7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?o522a7') format('truetype'),
    url('fonts/icomoon.woff?o522a7') format('woff'),
    url('fonts/icomoon.svg?o522a7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-size: 20px;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-check-empty:before {
    content: "\e901";
    color: #DDDEDE;
}
.icon-check-full:before {
    content: "\e902";
    color: $teal;
}
.icon-checkmark:before {
    content: "\ea10";
    color: $teal;
}
.icon-radio-empty:before {
    content: "\e903";
    color: #DDDEDE
}
.icon-radio-full:before {
    content: "\e905";
    color: $teal;
}

.icon-contacted:before {
    content: "\e913";
    color: #54585a;
}
.icon-more:before {
    content: "\e90c";
    color: #54585a;
    // color: #000;
}
.icon-dollar:before {
    content: "\e90c";
}
.icon-search:before {
    content: "\e90d";
    color: #54585a;
}
.icon-eye:before {
    content: "\e90e";
    color: #54585a;
}
.icon-fb:before {
    content: "\e90f";
    color: #fff;
}
.icon-plus:before {
    content: "\e910";
}

.icon-question-mark:before {
    content: "\e911";
    color: #A9ABAC;
}

.icon-arrow-down:before {
    content: "\e90a";
    color: $teal;
}
.icon-close-button:before {
    content: "\e909";
    color: #cc4758;
    cursor: pointer;
}
.icon-close-button.grey:before {
    content: "\e909";
    color: #53585a;
}
.icon-arrow:before {
    content: "\e900";
    color: #a6a1b8;
}
.icon-list:before {
    content: "\e90b";
    color: #54585a;
}
.icon-lock:before {
    content: "\e907";
    color: #53585A;
}
.icon-lock.red:before {
    color: #ff596f;
}
.icon-lock.cyan:before {
    color: #3EC6C0;
}
.icon-heart:before {
    content: "\e908";
    color: #53585A;
}

.icon-heart-white:before {
  content: "\e908";
  color: #fff;
}

.icon-heart.red:before {
    color: #ff596f;
}
.icon-heart.cyan:before {
    color: #38B2AC;
}
.icon-back-button:before {
    content: "\e904";
    color: #53585a;
}
.icon-empty:before {
    content: "\e905";
    color: rgba(0, 0, 0, 0.4);
}
.icon-full:before {
    content: "\e906";
    color: rgba(0, 0, 0, 0.4);
}
.icon-square-empty:before {
    content: "\e901";
    color: rgba(0, 0, 0, 0.4);
}
.icon-square-full .path1:before {
    content: "\e902";
    color: rgba(0, 0, 0, 0.4);
}
.icon-square-full .path2:before {
    content: "\e903";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-green-bird:before {
    content: "\e910";
    color: $teal;
}
.icon-yellow-bird:before {
    content: "\e912";
    color: #ffda65;
}

.matchmaker-options {
    min-height: 300px;
    &__title {
        color: #53585a;
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        margin: 0 0 4px 0;
        letter-spacing: -0.2px;
        &.small {
            font-size: 16px;
        }
    }
    .input-wrapper {
        max-width: 180px;
        .input-name-label {
            font-size: 14px;
        }
        .input-text {
            height: 26px;
            font-size: 12px;
        }
    }
    .input-checkbox {
        min-height: 32px;
        .checkbox__label {
            padding-left: 12px;
        }
    }
    @media screen and (max-device-width: 770px) {
        min-height: 800px;
        .input-checkbox {
            min-height: 100px;
            width: 500px;
            margin: 0;
            .icon-check-empty, .icon-check-full {
                font-size: 60px;
            }
            .checkbox-icon {
                margin-top: -20px;
            }
            .checkbox__label {
                font-size: 40px;
                line-height: 40px;
            }
        }
        .input-wrapper {
            max-width: 430px;
            .input-text {
                font-size: 35px;
            }
        }
    }
}
@import '../../../../src/scss/variables';

.home-page-container {
    font-family: 'Montserrat', sans-serif;

    &__content {
        .section-one {
            background: url('assets/homepage/LadyonBeach.jpg') no-repeat 0 0;
            background-size: cover;
            border-bottom: 1px solid #eee;
            height: 553px;
            // margin-top: 80px;
            padding-top: 155px;
            position: relative;
            transition: all 0.5s ease;
            @media screen and (min-device-width: 781px) {
                background-position: 0 -100px;
            }

            .section-title {
                background-color: rgba(0, 0, 0, 0.4);
                height: 138px;
                margin-top: 5px;
                position: relative;
                transition: all 0.1s ease;
                max-width: 630px;
                z-index: 2;

                span {
                    color: #fff;
                    font-family: Montserrat, sans-serif;
                    font-size: 36px;
                    font-weight: bold;
                    line-height: 41px;
                    padding: 25px 40px 25px 120px;
                    text-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                    white-space: nowrap;
                }
            }

            .button {
                margin: 41px 0 0 120px;
                padding-left: 0;
                padding-right: 0;
                position: absolute;
                z-index: 2;
            }
            @media screen and (max-device-width: 780px) {
                .couple-info {
                    max-width: 240px;
                }
            }
        }

        .section-two {
            background: #fff;
            &__content {
                padding: 60px 0 0;
                max-width: 880px;
                width: 100%;
                &.special-padding {
                    padding: 60px 0;
                }
            }
            &__title {
                margin-bottom: 25px;
                .title-text {
                    color: $teal;
                    font-size: 40px;
                    font-weight: 300;
                    font-family: Montserrat, sans-serif;
                    line-height: 49px;
                    padding: 0 20px;
                    white-space: pre-wrap;
                    text-transform: uppercase;
                    letter-spacing: -1px;
                    @media screen and (max-device-width: 780px) {
                        font-size: 30px;
                    }
                }
                .blue-line {
                    background-color: $teal;
                    height: 2px;
                    width: 100px;
                }

                &.special .title-text {
                    color: #54585a;
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 44px;
                    letter-spacing: -0.1px;
                    padding: 0;
                    text-align: left;
                    text-transform: none;
                }
            }
            &__tiers {
                // color:#54585a;
                // font-size: 20px;
                // font-family: 'Montserrat', sans-serif;
                // line-height: 32px;
                // white-space: pre-wrap;
                padding: 30px 0 0;
                max-width: 880px;
                width: 100%;
                &.special-padding {
                    padding: 60px 0;
                }
            }
        }

        .section-three {
            width: 100%;
        }

        .section-four {
            width: 100%;
            .section-two__content {
                padding-top: 60px;
            }
            &__content {
                padding: 0 0 40px 0;
                .example-members {
                    color: #54585a;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 20px;
                    margin: 30px auto 25px;
                }
                @media screen and (max-device-width: 780px) {
                    img {
                        max-width: 180px;
                        width: 100%;
                    }
                }
            }
            .slick-arrow {
                background-color: #ffda65;
                border-radius: 0 24px 24px 0;
                cursor: pointer;
                height: 48px;
                position: absolute;
                width: 48px;
                &.prev-arrow {
                    left: 0;
                }
                &.next-arrow {
                    right: 0;
                    transform: rotate(180deg);
                }
            }
        }

        .section-five {
            background-color: #fff;
            .section-two__content {
                padding-bottom: 60px;
            }
            &__content {
                width: 100%;
                padding: 0 0 40px 0;
                max-width: 1155px;
                .section-two__title {
                    margin-bottom: 0;
                }
            }
            &__item {
                margin-bottom: 80px;
                align-items: initial;
                &__image {
                    background-color: #f9db78;
                    border-radius: 50%;
                    height: 250px;
                    margin-right: 65px;
                    min-width: 250px;
                }
                &__right {
                    .title {
                        color: #54585a;
                        font-size: 32px;
                        font-weight: 800;
                        font-family: Montserrat, sans-serif;
                        line-height: 44px;
                        padding-bottom: 40px;
                    }
                    .one {
                        margin-bottom: 20px;
                    }
                }
                &.transparency {
                    margin-bottom: 60px;
                }

                @media screen and (max-device-width: 780px) {
                    margin-bottom: 20px;
                    padding: 0 20px;
                    &__image {
                        min-width: 100px;
                        height: 100px;
                        margin-right: 15px;
                        img {
                            max-width: 100px;
                        }
                    }
                    &__right .title {
                        padding-bottom: 10px;
                    }
                }
            }
        }

        .section-six {
            width: 100%;
            // min-height: 700px;
            padding-bottom: 65px;
            &__content {
                position: relative;

                .dark-grey-section {
                    width: calc(100% - 500px);
                    &__text {
                        color: #54585a;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 20px;
                        line-height: 32px;
                    }
                }

                .background-image {
                    background: url('assets/homepage-singles/jakeandkim.jpg') no-repeat;
                    background-size: cover;
                    border: 10px solid #f0c948;
                    height: 540px;
                    max-width: 400px;
                    width: 100%;
                    margin: 0 100px;
                    z-index: 3;
                    .couple-info {
                        margin: auto 0 20px auto;
                        max-width: 230px;
                    }
                }
            }
            &.homepage-section-six {
                background: url('assets/homepage/arnellandbecky.jpg') no-repeat;
                background-size: cover;
                height: 800px;
                .section-title {
                    background-color: rgba(0, 0, 0, 0.4);
                    height: 42px;
                    z-index: 2;
                    color: #fff;
                    font-family: Montserrat, sans-serif;
                    font-size: 36px;
                    font-weight: bold;
                    line-height: 41px;
                    padding: 21px 30px 29px 120px;
                    width: 540px;
                    margin: 200px 0 41px;
                }
                .button.yellow {
                    margin-left: 125px;
                }
            }
        }

        .section-seven {
            background: #fff;
            width: 100%;
            .section-two__title {
                margin-bottom: 15px;
            }
            &__content {
                padding: 0 0 100px 0;
                .button {
                    margin: 0 auto;
                }
            }
        }

        .grey-background {
            .special-padding {
                padding-top: 0;
            }
        }

        .text {
            color: #54585a;
            font-size: 20px;
            font-family: 'Montserrat', sans-serif;
            line-height: 32px;
            white-space: pre-wrap;
        }

        .margin-30-bottom {
            margin-bottom: 30px;
        }

        .special-text {
            color: #54585a;
            font-size: 20px;
            font-weight: bold;
            line-height: 40px;
            text-align: center;
            max-width: 740px;
            margin-bottom: 80px;
        }

        .match-makers-page-content {
            .section-six {
                background: url('assets/homepage-matchmakers/kristenAndWill.jpg')
                    no-repeat 0 -10px;
                &__content {
                    padding: 0;
                }
                .section-title {
                    background-color: rgba(0, 0, 0, 0.4);
                    height: 92px;
                    max-width: 420px;
                    justify-content: flex-start;
                    margin: 25% 0 38px 0;
                    padding-left: 10%;
                    width: 100%;
                    span {
                        color: #fff;
                        font-size: 36px;
                        font-weight: 900;
                        line-height: 49px;
                        text-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                    }
                }
                button {
                    margin: 0 0 60px 10%;
                }
            }

            .white-spacing {
                background: #fff;
                height: 100px;
                width: 100%;
            }

            .matchmaker-section-three {
                width: 100%;
                &__content {
                    padding: 0;
                    max-width: 967px;
                    .title {
                        color: $teal;
                        font-family: Montserrat, sans-serif;
                        font-size: 32px;
                        font-weight: 900;
                        line-height: 39px;
                        margin-bottom: 81px;
                        text-align: center;
                        width: 100%;
                        margin-top: 40px;
                    }
                    .item {
                        padding-bottom: 80px;
                        width: 38.4%;
                        align-self: flex-start;
                        &.margin-right {
                            margin-right: 11%;
                        }
                        &.margin-left {
                            margin-left: 11%;
                        }
                        &__svg {
                            max-width: 250px;
                            width: auto;
                        }
                        &__subtitle {
                            color: #54585a;
                            font-family: Montserrat, sans-serif;
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 27px;
                            margin: 40px 0 20px 0;
                        }
                        .text {
                            text-align: center;
                        }
                        //.extra {
                        //    color: #54585A;
                        //    font-family: 'Montserrat', sans-serif;
                        //    font-size: 18px;
                        //    padding-bottom: 20px;
                        //    width: 100%;
                        //    .circle {
                        //        background-color: #FFDA65;
                        //        border-radius: 50%;
                        //        height: 22px;
                        //        margin-right: 10px;
                        //        width: 22px;
                        //        &.blue {
                        //            background-color: $teal;
                        //            margin-left: 20px;
                        //        }
                        //    }
                        //}
                    }
                }
            }

            .section-two {
                &.pricing {
                    background-color: rgba(84, 88, 90, 0.1);
                    padding: 0 50px;
                }
                &.pricing .section-two__content {
                    padding-top: 60px;
                }
                .plans {
                    background-color: #fff;
                    border: 1px solid rgba(84, 88, 90, 0.2);
                    margin: 20px 50px 60px 50px;
                    max-width: 1208px;
                    &__margin {
                        margin: 21px 41px 31px 31px;
                    }
                    &__titles,
                    &__connections,
                    &__benefits {
                        border-bottom: 1px solid rgba(84, 88, 90, 0.2);
                    }
                    &__tab {
                        color: $teal;
                        font-family: Montserrat, sans-serif;
                        font-size: 16px;
                        font-weight: 900;
                        min-height: 81px;
                    }
                    &__tab.plan {
                        padding-left: 44px;
                        min-width: 100px;
                    }
                    &__tab.connections {
                        min-height: 79px;
                        padding: 21px 3px 0 10px;
                        min-width: 131px;
                    }
                    &__tab.benefits {
                        min-height: 124px;
                        padding: 21px 3px 0 10px;
                        min-width: 131px;
                        letter-spacing: -0.3px;
                    }
                    &__tab.price {
                        min-height: 94px;
                        padding: 34px 0 0 44px;
                        min-width: 100px;
                    }
                    .item__text,
                    .item__title {
                        border-left: 1px solid rgba(84, 88, 90, 0.2);
                        max-width: 252px;
                        text-align: center;
                        min-height: 80px;
                        width: 100%;
                    }
                    .item__title {
                        font-family: Montserrat, sans-serif;
                        .plan-name {
                            color: $teal;
                            font-size: 20px;
                            font-weight: 900;
                            line-height: 24px;
                            @media screen and (max-device-width: 780px) {
                                font-size: 16px;
                            }
                        }
                        .plan-title {
                            color: rgba(84, 88, 90, 0.5);
                            font-size: 16px;
                            line-height: 19px;
                            max-width: 80%;
                            margin: 0 auto;
                            @media screen and (max-device-width: 780px) {
                                font-size: 12px;
                            }
                        }
                    }
                    .item__text {
                        color: #54585a;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 16px;
                        line-height: 22px;
                        @media screen and (max-device-width: 780px) {
                            font-size: 14px;

                        }
                    }
                    &__connections {
                        max-height: 97px;
                        .item__text {
                            max-width: 232px;
                            min-height: 77px;
                            padding: 20px 0 0 20px;
                            text-align: left;
                        }
                    }
                    &__benefits {
                        .benefits-content {
                            max-width: 232px;
                            border-left: 1px solid rgba(84, 88, 90, 0.2);
                            padding: 20px 0 0 20px;
                            width: 100%;
                        }
                        .item__text {
                            text-align: left;
                            border-left: none;
                        }
                        .item__text:nth-child(2) {
                            margin-top: 30px;
                            min-height: 43px;
                        }
                        .custom-bottom {
                            color: #fff;
                        }
                    }
                    &__prices {
                        max-height: 134px;
                        .item__text {
                            border: none;
                            min-height: 47px;
                            width: 50%;
                            &.plan-price {
                                color: #ffc134;
                                font-family: Montserrat, sans-serif;
                                font-size: 32px;
                                font-weight: 900;
                                text-align: right;
                                margin-left: 28px;
                                @media screen and (max-device-width: 1200px) {
                                    margin-left: 10px;
                                    font-size: 22px;
                                }
                            }
                            &.continue {
                                text-align: left;
                                margin: 12px 0 0 2px;
                            }
                        }
                        .button {
                            height: 46px;
                            padding: 0;
                            max-width: 173px;
                            width: 100%;
                            margin: 0 auto;
                        }
                        .first {
                            .plan-price {
                                text-align: center;
                                margin: 6px 0;
                            }
                            .continue {
                                display: none;
                            }
                        }
                        .price-content {
                            border-left: 1px solid rgba(84, 88, 90, 0.2);
                            max-width: 228px;
                            padding: 32px 10px 0 10px;
                            width: 100%;
                        }
                    }
                }
            }

            .exclusive-contact {
                background-color: #fff;
                padding: 0 50px;
                .section-two__content {
                    padding: 60px 0 40px 0;
                    max-width: 1050px;
                }
                .lock-acquire {
                    color: #54585a;
                    font-family: Montserrat, sans-serif;
                    font-size: 20px;
                    font-weight: bold;
                    margin: 0 0 40px 0;
                }
                .lock-image {
                    background: url('assets/homepage-matchmakers/yellow-lock.svg')
                        no-repeat;
                    height: 380.09px;
                    width: 460px;
                }
                .lock-text {
                    color: #54585a;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 20px;
                    font-weight: bold;
                    // letter-spacing: 0.2px;
                    line-height: 27px;
                    width: 39%;
                    &.right {
                        width: 38%;
                    }
                    &.or {
                        position: absolute;
                        top: 207px;
                        left: 220px;
                    }
                }
                .text {
                    max-width: 880px;
                    margin: 0 auto;
                    &.last {
                        padding: 41px 0 80px 0;
                    }
                }
            }

            .spread-love {
                background-color: rgba(84, 88, 90, 0.1);
                .section-two__content {
                    padding: 80px 0 60px 0;
                }
            }
        }

        .singles-homepage-content {
            .section-two:first-child .section-two__content {
                max-width: 100%;
                .section-two__title {
                    margin-bottom: 0;
                }
            }
            .singles-why-section {
                background: rgba(84, 88, 90, 0.1);
                margin: 0 auto;
                padding-bottom: 30px;
                .section-two__content {
                    padding: 58px 0;
                    background: rgba(84, 88, 90, 0.1);
                }
                &__item {
                    padding: 30px 19.2%;
                    @media screen and (max-device-width: 780px) {
                        padding: 30px 50px;
                    }
                    .title {
                        color: #54585a;
                        font-family: Montserrat, sans-serif;
                        font-size: 20px;
                        font-weight: 700;
                        margin-left: 20px;
                    }
                    .text {
                        color: #54585a;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 20px;
                        margin-left: 54px;
                    }
                }
            }
            .carla-swiryn {
                color: #54585a;
                max-width: 880px;
                margin: 0 auto;
                position: relative;
                top: -20px;
                text-align: right;
                width: 100%;
                span:first-child {
                    font-family: Montserrat, sans-serif;
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }
                span:nth-child(2) {
                    font-size: 16px;
                    font-weight: 300;
                }
            }
            .singles-first-section {
                background: rgba(84, 88, 90, 0.1);
                .section-two__content {
                    padding: 80px 0 0 0;
                }
                .item {
                    font-family: 'Montserrat', sans-serif;
                    height: 200px;
                    padding: 0 15%;
                    .item-text {
                        color: #54585a;
                        font-size: 20px;
                        line-height: 32px;
                        white-space: pre-wrap;
                    }
                    .before-circle,
                    .after-circle {
                        border-radius: 50%;
                        font-size: 42.67px;
                        font-weight: 900;
                        height: 100px;
                        margin: 0 70px 0 0;
                        width: 100px;
                    }
                    .before-circle {
                        background-color: #f9db78;
                        color: #54585a;
                    }
                    .after-circle {
                        background-color: #7dc0bd;
                        color: #fff;
                        margin-left: 33%;
                    }
                    @media screen and (max-width: 1250px) {
                        padding: 0 10%;
                        .after-circle {
                            margin-left: 14%;
                        }
                    }

                    @media screen and (max-device-width: 780px) {
                        padding: 0 5%;
                    }
                }
            }
            .singles-third-section {
                background: rgba(84, 88, 90, 0.1);
                padding: 15px 0 80px 0;
                width: 100%;
                > span {
                    color: $teal;
                    font-family: Montserrat, sans-serif;
                    font-size: 40px;
                    font-weight: 500;
                    line-height: 49px;
                }
                .custom-text {
                    color: #54585a;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 20px;
                    line-height: 32px;
                    margin: 20px 50px 40px 50px;
                }
            }
            .singles-section-seven {
                background: #fff;
                .section-two__content {
                    max-width: 1180px;
                }
                .button {
                    margin-bottom: 0;
                }
            }
            .our-partners {
                background-color: rgba(84, 88, 90, 0.1);
                padding: 0 0 40px 0;
                margin-top: 40px;
                width: 100%;
                .section-two__content {
                    max-width: 100%;
                    padding: 30px 0 30px 0;
                    .section-two__title {
                        margin-bottom: 0;
                    }
                }
                &__title {
                    color: #54585a;
                    font-family: Montserrat, sans-serif;
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 39px;
                    padding-bottom: 40px;
                    text-align: center;
                }
                &__content {
                    max-width: 1180px;
                    .partners {
                        padding: 0;
                        max-width: 980px;
                        margin: 0 auto;
                        .left,
                        .right {
                            width: 300px;
                            span {
                                color: #54585a;
                                font-size: 16px;
                                font-family: 'Montserrat', sans-serif;
                                line-height: 30px;
                                text-align: center;
                            }
                        }
                        .right {
                            margin-left: auto;
                        }
                    }
                }
                .partners-photo {
                    align-self: center;
                    border-radius: 50%;
                    border: 4px solid $teal;
                    margin: 0 55px;
                    height: 355px;
                    max-width: 355px;
                    padding: 4px;
                    width: 100%;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            .companies {
                padding: 80px 0 40px 0;
                background: #fff;
                &__titles {
                    padding: 21px 41px 20px 225px;
                }
                &__unique,
                &__pricing,
                &__bestFor {
                    align-items: flex-start;
                    background-color: rgba(84, 88, 90, 0.1);
                    padding: 21px 0 20px 41px;
                }
                &__bios,
                &__locations,
                &__contact {
                    align-items: flex-start;
                    background-color: #fff;
                    padding: 21px 0 20px 41px;
                }
                &__contact {
                    padding-bottom: 52px;
                }
                &__title {
                    color: #54585a;
                    font-family: Montserrat, sans-serif;
                    font-size: 40px;
                    font-weight: 300;
                    line-height: 49px;
                    max-width: 700px;
                    text-align: center;
                }
                &__intro {
                    color: #54585a;
                    font-size: 20px;
                    font-weight: bold;
                    font-family: 'Montserrat', sans-serif;
                    max-width: 780px;
                    padding: 15px 0 19px 0;
                    text-align: center;
                }
                &__content {
                    border: 1px solid rgba(84, 88, 90, 0.2);
                    box-shadow: 0 2px 14px 0 rgba(84, 88, 90, 0.5);
                    max-width: 1200px;
                    width: 100%;
                }
                .item {
                    &__title {
                        color: #7dc0bd;
                        font-size: 24px;
                        font-weight: bold;
                        text-align: center;
                        width: 33%;
                    }
                    &__text {
                        color: #54585a;
                        font-size: 16px;
                        font-family: 'Montserrat', sans-serif;
                        line-height: 22px;
                        margin: 0 20px;
                        max-width: 288px;
                        width: 100%;
                    }
                }
                &__tab {
                    color: #ffc134;
                    font-family: Montserrat, sans-serif;
                    font-size: 16px;
                    font-weight: 800;
                    padding: 0;
                    min-width: 184px;
                    width: 184px;
                    white-space: pre-wrap;
                }
                .button.yellow {
                    height: 54px;
                    padding: 0;
                    width: 176px;
                    .value {
                        white-space: pre-wrap;
                    }
                }
            }
        }

        &.for-singles .section-one {
            background: url('assets/homepage-singles/smilingcouple.jpg')
                no-repeat -80px -380px;
                background-position: bottom -70px left -50px;
            @media screen and (max-device-width: 780px) {
                background: url('assets/homepage-singles/smilingcouple.jpg');
                background-position: bottom -70px left -300px;
                    no-repeat: -280px -450px;
            }
        }

        &.for-matchmakers .section-one {
            background: url('assets/homepage-matchmakers/matchmakers-top-section.jpg')
                no-repeat 0 0;
            background-size: cover;
            @media screen and (min-device-width: 781px) {
                background-position: 0 -100px;
            }
        }

        .talk-to-a-mm-signup .modal {
            min-height: 250px;
            width: 30%;
            &__content {
                padding: 40px;
            }
            .message {
                color: #53585a;
                font-size: 18px;
                font-family: 'Montserrat', sans-serif;
                margin-bottom: 20px;
            }
            .button {
                margin: 0 auto;
            }
        }
    }
}
